const message = [
  {
    id: 1,
    message: "New Order from leverPay USERIDRxff15267",
    read: false,
    userId: "USERIDRxff15267",
    items: [
      {
        id: 1,
        name: "Gold watch",
        qty: 2,
        price: "50000",
        description: "gold plated fashion watch for men",
      },
    ],
  },
  {
    id: 2,
    message: "Transaction Approved by leverPay USERIDRdvXc3219",
    read: true,
  },
  { id: 3, message: "Invoice Sent to leverPay USERIDSxffrs2314", read: false },
];

export default message;
