import React from "react";
import ProfileElements from "../Components/Profile/profileElements";

export default function Profile() {
  return (
    <div>
      <ProfileElements />
    </div>
  );
}
