export const TableHead = ["PENDING", "PAID", "CANCELED"];

export const HeaderData = [
  "Email",
  "Product",
  "Price",
  "View",
];

export const data = [
  {
    id: 1,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "1",
    details: "View",
    status: "Received",
  },
  {
    id: 2,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "Sent",
  },
  {
    id: 3,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "1",
    details: "View",
    status: "Sent",
  },
  {
    id: 4,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "failed",
  },
  {
    id: 5,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "failed",
  },
  {
    id: 6,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "Sent",
  },
  {
    id: 7,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "1",
    details: "View",
    status: "failed",
  },
  {
    id: 8,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "1",
    details: "View",
    status: "Received",
  },
  {
    id: 9,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "Received",
  },
  {
    id: 10,
    date: "12/23/2:00am",
    SenderID: "LvP345mmmx23",
    ReceiverID: "John",
    productName: "Macbook 14 pro",
    Notification: "0",
    details: "View",
    status: "Sent",
  },
];
