import React from "react";
import Subscription_el from "../../Components/Subscription-components/subscription";

export default function Subscription() {
  return (
    <div className="subscription-container">
      <Subscription_el />
    </div>
  );
}
