export const TableHead = ["ALL", "PENDING", "ACTIVE", "CANCELED", "FAILED"];

export const HeaderData = [
  "Firstname",
  "Email",
  "Date",
  // "Lastname",
  // "Phone",
  "Duration",
  "Start Date",
  "End Date",
  "Paid",
  "View",
];

export const data = [
  {
    id: 1,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Canceled",
    link: "view",
  },
  {
    id: 2,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "pending",
    link: "view",
  },
  {
    id: 3,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Active",
    link: "view",
  },
  {
    id: 4,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Failed",
    link: "view",
  },
  {
    id: 5,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Active",
    link: "view",
  },
  {
    id: 6,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "failed",
    link: "view",
  },
  {
    id: 7,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Active",
    link: "view",
  },
  {
    id: 8,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Pending",
    link: "view",
  },
  {
    id: 9,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Pending",
    link: "view",
  },
  {
    id: 10,
    firstname: "Nina",
    lastname: "Okoye",
    email: "Nina@mail.com",
    date: "12/05/23",
    phone: "080123456789",
    Duration: "1year",
    startDate: "19/10/23",
    endDate: "10/10/23",
    amountPaid: "30000",
    paid: "yes",
    status: "Failed",
    link: "view",
  },
];
